import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {btApi} from '../../app/init.js'


export const addNewInvoice = createAsyncThunk('/invoice/new', async (invoiceObj, thunkAPI) => {
    try {
        const response = await btApi.post(
            '/api/v1/invoices',
            invoiceObj.newInvoiceObj
        );
        return response.data;
    } catch (err) {
        console.log(err);
        let errMessage = "Server Error"
        let status = 500
        try {
            if (err.response.status < 500 && err.response.status > 399) {
                errMessage = err.response.data.message
                status = err.response.status
            }
        } catch {}
        return thunkAPI.rejectWithValue({data: {message: errMessage}, status: status});
    }
})


export const getInvoice = createAsyncThunk('/invoice/get', async (invoiceObj, thunkAPI) => {
    try {
        const response = await btApi.get(
            `/api/v1/invoices/${invoiceObj.invoiceKey}`
        );
        return response.data;
    } catch (err) {
        console.log(err);
        let errMessage = "Server Error"
        let status = 500
        try {
            if (err.response.status < 500 && err.response.status > 399) {
                errMessage = err.response.data.message
                status = err.response.status
            }
        } catch {}
        return thunkAPI.rejectWithValue({data: {message: errMessage}, status: status});
    }
})


export const updateInvoice = createAsyncThunk('/invoice/update', async (invoiceObj, thunkAPI) => {
    try {
        const response = await btApi.patch(
            `/api/v1/invoices/${invoiceObj.updateInvoiceObj.key}`,
            invoiceObj.updateInvoiceObj
        );
        return response.data;
    } catch (err) {
        console.log(err);
        let errMessage = "Server Error"
        let status = 500
        try {
            if (err.response.status < 500 && err.response.status > 399) {
                errMessage = err.response.data.message
                status = err.response.status
            }
        } catch {}
        return thunkAPI.rejectWithValue({data: {message: errMessage}, status: status});
    }
})


export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: {
        isLoadingInvoice: false,
        invoiceKey: null,
        invoice : {},
        invoiceerror: null,
        invoicehttpcode: null,
        invoiceObjectChanged : false
    },
    reducers: {
        clearInvoice: state => {
            state.invoiceKey = null
            state.invoice = {}
            state.invoiceerror = null
            state.invoicehttpcode = null
        },
        clearInvoiceError: state => {
            state.invoiceerror = null
            state.invoicehttpcode = null
        },
        clearInvoiceStatus: state => {
            state.invoiceObjectChanged = false
        }
    },

    extraReducers: (builder) => {
        builder
        .addCase(addNewInvoice.pending, (state, action) => {
            state.invoiceKey = null
            state.invoice = {}
            state.invoiceerror = null
            state.invoicehttpcode = null
            state.isLoadingInvoice = true
            state.invoiceObjectChanged = false
        })
        .addCase(addNewInvoice.fulfilled, (state, action) => {
            state.invoiceKey = action.payload.key
            state.invoice = {}
            state.invoiceerror = null
            state.invoicehttpcode = 200
            state.isLoadingInvoice = false
            state.invoiceObjectChanged = false
        })
        .addCase(addNewInvoice.rejected, (state, action) => {
            state.invoiceKey = null
            state.invoice = {}
            state.invoiceerror = action.payload.data.message
            state.invoicehttpcode = action.payload.status
            state.isLoadingInvoice = false
            state.invoiceObjectChanged = false
        })


        .addCase(getInvoice.pending, (state, action) => {
            state.invoiceKey = null
            state.invoice = {}
            state.invoiceerror = null
            state.invoicehttpcode = null
            state.isLoadingInvoice = true
            state.invoiceObjectChanged = false
        })
        .addCase(getInvoice.fulfilled, (state, action) => {
            state.invoiceKey = null
            state.invoice = action.payload
            state.invoiceerror = null
            state.invoicehttpcode = 200
            state.isLoadingInvoice = false
            state.invoiceObjectChanged = false
        })
        .addCase(getInvoice.rejected, (state, action) => {
            state.invoiceKey = null
            state.invoice = {}
            state.invoiceerror = action.payload.data.message
            state.invoicehttpcode = action.payload.status
            state.isLoadingInvoice = false
            state.invoiceObjectChanged = false
        })

        .addCase(updateInvoice.pending, (state, action) => {
            state.invoiceKey = null
            state.invoiceerror = null
            state.invoicehttpcode = null
            state.isLoadingInvoice = true
            state.invoiceObjectChanged = false
        })
        .addCase(updateInvoice.fulfilled, (state, action) => {
            state.invoiceKey = null
            state.invoice = action.payload
            state.invoiceerror = null
            state.invoicehttpcode = 200
            state.isLoadingInvoice = false
            state.invoiceObjectChanged = true
        })
        .addCase(updateInvoice.rejected, (state, action) => {
            state.invoiceKey = null
            state.invoiceerror = action.payload.data.message
            state.invoicehttpcode = action.payload.status
            state.isLoadingInvoice = false
            state.invoiceObjectChanged = false
        })
    }
})

export const { clearInvoiceError, clearInvoice, clearInvoiceStatus } = invoiceSlice.actions

export default invoiceSlice.reducer
