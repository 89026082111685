import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import customersSlice from '../features/customers/customersSlice'
import customerSlice from '../features/customer/customerSlice'
import invoicesSlice from '../features/invoices/invoicesSlice'
import invoiceSlice from '../features/invoice/invoiceSlice'

const combinedReducer = {
  header : headerSlice,
  rightDrawer : rightDrawerSlice,
  modal : modalSlice,
  customers : customersSlice,
  customer : customerSlice,
  invoices : invoicesSlice,
  invoice : invoiceSlice,
}

export default configureStore({
    reducer: combinedReducer
})
