import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {btApiLoad} from '../../app/init.js'
import {btApi} from '../../app/init.js'


export const getCustomersContent = createAsyncThunk('/customers/content', async (customerQuery, thunkAPI) => {
    try {
        // const filters = Object.entries(customerQuery)
        //           .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        //           .join('&')
        const filters = customerQuery.map((i, k) =>{
            if(i !== null)return `${encodeURIComponent(i[0])}=${encodeURIComponent(i[1])}`
            return 'null=null'
        }).join('&')

        const response = await btApiLoad.get(
            `/api/v1/customers?${filters}`
        );
        return response.data;
    } catch (err) {
        console.log(err);
        let errMessage = "Server Error"
        let status = 500
        try {
            if (err.response.status < 500 && err.response.status > 399) {
                errMessage = err.response.data.message
                status = err.response.status
            }
        } catch {}
        return thunkAPI.rejectWithValue({data: {message: errMessage}, status: status});
    }
})


export const updateCustomerObject = createAsyncThunk('/customers/update', async (key, thunkAPI) => {
    try {
        const response = await btApi.get(
            `/api/v1/customers/${key.key}`
        );
        return response.data;
    } catch (err) {
        console.log(err);
        let errMessage = "Server Error"
        let status = 500
        try {
            if (err.response.status < 500 && err.response.status > 399) {
                errMessage = err.response.data.message
                status = err.response.status
            }
        } catch {}
        return thunkAPI.rejectWithValue({data: {message: errMessage}, status: status});
    }
})


export const customersSlice = createSlice({
    name: 'customers',
    initialState: {
        isLoadingCustomers: false,
        customers : [],
        customerserror: null,
        customershttpcode: null
    },
    reducers: {
        clearCustomersError: state => {
            state.customerserror = null
            state.customershttpcode = null
        },
        clearCustomers: state => {
            state.customers = []
            state.customerserror = null
            state.customershttpcode = null
        }
    },

    extraReducers: (builder) => {
        builder
        .addCase(getCustomersContent.pending, (state, action) => {
            state.customers = []
            state.customerserror = null
            state.customershttpcode = null
            state.isLoadingCustomers = true
        })
        .addCase(getCustomersContent.fulfilled, (state, action) => {
            state.customers = action.payload.values
            state.customerserror = null
            state.customershttpcode = null
            state.isLoadingCustomers = false
        })
        .addCase(getCustomersContent.rejected, (state, action) => {
            state.customers = []
            state.customerserror = action.payload.data.message
            state.customershttpcode = action.payload.status
            state.isLoadingCustomers = false
        })
        .addCase(updateCustomerObject.fulfilled, (state, action) => {
            const newCustomer = action.payload

            // Loop and update the one object
            const updatedData = state.customers.map(item => {
              if (item.key === newCustomer.key) {
                return newCustomer;
              }
              return item;
            });
            state.customers = updatedData
        })
        .addCase(updateCustomerObject.rejected, (state, action) => {
            state.customerserror = action.payload.data.message
            state.customershttpcode = action.payload.status
        })
    }
})

export const { clearCustomers, clearCustomersError } = customersSlice.actions

export default customersSlice.reducer
