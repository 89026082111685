import axios from "axios"

const btapi = axios.create({});
const btapiload = axios.create({});
const btapiopen = axios.create({});

function apiRequestStart(config) {
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add('loading-indicator');
    return config
}

function apiRequestStartError(error) {
    return Promise.reject(error);
}

function apiRequestEnd(response) {
    // UPDATE: Add this code to hide global loading indicator
    document.body.classList.remove('loading-indicator');
    return response;
}

function apiRequestEndError(error) {
    document.body.classList.remove('loading-indicator');
    return Promise.reject(error);
}


const initializeApp = () => {

    btapi.defaults.baseURL = process.env.REACT_APP_BTAPI
    btapiload.defaults.baseURL = process.env.REACT_APP_BTAPI
    btapiopen.defaults.baseURL = process.env.REACT_APP_BTAPI

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code

    } else {
        // Prod build code

        // Removing console.log from prod
        console.log = () => {};

        // init analytics here
    }

    btapiload.interceptors.request.use(apiRequestStart, apiRequestStartError);
    btapiload.interceptors.response.use(apiRequestEnd, apiRequestEndError);
}

export const btApi = btapi
export const btApiLoad = btapiload
export const btApiOpen = btapiopen

export default initializeApp
