import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {btApi} from '../../app/init.js'


export const addNewCustomer = createAsyncThunk('/customer/new', async (customerObj, thunkAPI) => {
    try {
        const response = await btApi.post(
            '/api/v1/customers',
            customerObj.newCustomerObj
        );
        return response.data;
    } catch (err) {
        console.log(err);
        let errMessage = "Server Error"
        let status = 500
        try {
            if (err.response.status < 500 && err.response.status > 399) {
                errMessage = err.response.data.message
                status = err.response.status
            }
        } catch {}
        return thunkAPI.rejectWithValue({data: {message: errMessage}, status: status});
    }
})


export const getCustomer = createAsyncThunk('/customer/get', async (customerObj, thunkAPI) => {
    try {
        const response = await btApi.get(
            `/api/v1/customers/${customerObj.customerKey}`
        );
        return response.data;
    } catch (err) {
        console.log(err);
        let errMessage = "Server Error"
        let status = 500
        try {
            if (err.response.status < 500 && err.response.status > 399) {
                errMessage = err.response.data.message
                status = err.response.status
            }
        } catch {}
        return thunkAPI.rejectWithValue({data: {message: errMessage}, status: status});
    }
})


export const updateCustomer = createAsyncThunk('/customer/update', async (customerObj, thunkAPI) => {
    try {
        const response = await btApi.patch(
            `/api/v1/customers/${customerObj.updateCustomerObj.key}`,
            customerObj.updateCustomerObj
        );
        return response.data;
    } catch (err) {
        console.log(err);
        let errMessage = "Server Error"
        let status = 500
        try {
            if (err.response.status < 500 && err.response.status > 399) {
                errMessage = err.response.data.message
                status = err.response.status
            }
        } catch {}
        return thunkAPI.rejectWithValue({data: {message: errMessage}, status: status});
    }
})


export const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        isLoadingCustomer: false,
        customerKey: null,
        customer : {},
        customererror: null,
        customerhttpcode: null,
        customerObjectChanged: true
    },
    reducers: {
        clearCustomer: state => {
            state.customerKey = null
            state.customer = {}
            state.customererror = null
            state.customerhttpcode = null
        },
        clearCustomerError: state => {
            state.customererror = null
            state.customerhttpcode = null
        },
        clearCustomerStatus: state => {
            state.customerObjectChanged = false
        }
    },

    extraReducers: (builder) => {
        builder
        .addCase(addNewCustomer.pending, (state, action) => {
            state.customerKey = null
            state.customer = {}
            state.customererror = null
            state.customerhttpcode = null
            state.isLoadingCustomer = true
            state.customerObjectChanged = false
        })
        .addCase(addNewCustomer.fulfilled, (state, action) => {
            state.customerKey = action.payload.key
            state.customer = {}
            state.customererror = null
            state.customerhttpcode = 200
            state.isLoadingCustomer = false
            state.customerObjectChanged = false
        })
        .addCase(addNewCustomer.rejected, (state, action) => {
            state.customerKey = null
            state.customer = {}
            state.customererror = action.payload.data.message
            state.customerhttpcode = action.payload.status
            state.isLoadingCustomer = false
            state.customerObjectChanged = false
        })


        .addCase(getCustomer.pending, (state, action) => {
            state.customerKey = null
            state.customer = {}
            state.customererror = null
            state.customerhttpcode = null
            state.isLoadingCustomer = true
            state.customerObjectChanged = false
        })
        .addCase(getCustomer.fulfilled, (state, action) => {
            state.customerKey = null
            state.customer = action.payload
            state.customererror = null
            state.customerhttpcode = 200
            state.isLoadingCustomer = false
            state.customerObjectChanged = false
        })
        .addCase(getCustomer.rejected, (state, action) => {
            state.customerKey = null
            state.customer = {}
            state.customererror = action.payload.data.message
            state.customerhttpcode = action.payload.status
            state.isLoadingCustomer = false
            state.customerObjectChanged = false
        })

        .addCase(updateCustomer.pending, (state, action) => {
            state.customerKey = null
            state.customererror = null
            state.customerhttpcode = null
            state.isLoadingCustomer = true
            state.customerObjectChanged = false
        })
        .addCase(updateCustomer.fulfilled, (state, action) => {
            state.customerKey = null
            state.customer = action.payload
            state.customererror = null
            state.customerhttpcode = 200
            state.isLoadingCustomer = false
            state.customerObjectChanged = true
        })
        .addCase(updateCustomer.rejected, (state, action) => {
            state.customerKey = null
            state.customererror = action.payload.data.message
            state.customerhttpcode = action.payload.status
            state.isLoadingCustomer = false
            state.customerObjectChanged = false
        })
    }
})

export const { clearCustomerError, clearCustomer, clearCustomerStatus } = customerSlice.actions

export default customerSlice.reducer
