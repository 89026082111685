import { btApi, btApiLoad } from './init.js'

const checkAuth = () => {
/*  Getting token value stored in localstorage, if token is not present we will open login page
    for all internal dashboard routes  */
    const TOKEN = localStorage.getItem("jwt")
    const PUBLIC_ROUTES = ["login", "setup", "payment", "checkout"]

    console.log("Auth Check")

    const isPublicPage = PUBLIC_ROUTES.some( r => window.location.href.includes(r))

    if(!TOKEN && !isPublicPage){
        window.location.href = '/login'
        return;
    }else{
        btApi.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`
        btApiLoad.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`

        return TOKEN
    }
}

export default checkAuth
