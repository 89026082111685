import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {btApiLoad} from '../../app/init.js'
import {btApi} from '../../app/init.js'


export const getInvoicesContent = createAsyncThunk('/invoices/content', async (invoiceQuery, thunkAPI) => {
    try {
        // const filters = Object.entries(invoiceQuery)
        //           .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        //           .join('&')
        const filters = invoiceQuery.map((i, k) =>{
            if(i !== null)return `${encodeURIComponent(i[0])}=${encodeURIComponent(i[1])}`
            return 'null=null'
        }).join('&')

        const response = await btApiLoad.get(
            `/api/v1/invoices?${filters}`
        );
        return response.data;
    } catch (err) {
        console.log(err);
        let errMessage = "Server Error"
        let status = 500
        try {
            if (err.response.status < 500 && err.response.status > 399) {
                errMessage = err.response.data.message
                status = err.response.status
            }
        } catch {}
        return thunkAPI.rejectWithValue({data: {message: errMessage}, status: status});
    }
})


export const updateInvoiceObject = createAsyncThunk('/invoices/update', async (key, thunkAPI) => {
    try {
        const response = await btApi.get(
            `/api/v1/invoices/${key.key}`
        );
        return response.data;
    } catch (err) {
        console.log(err);
        let errMessage = "Server Error"
        let status = 500
        try {
            if (err.response.status < 500 && err.response.status > 399) {
                errMessage = err.response.data.message
                status = err.response.status
            }
        } catch {}
        return thunkAPI.rejectWithValue({data: {message: errMessage}, status: status});
    }
})


export const getInvoiceCustomersContent = createAsyncThunk('/invoices/customerscontent', async (customerQuery, thunkAPI) => {
    try {
        // const filters = Object.entries(customerQuery)
        //           .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        //           .join('&')
        const filters = customerQuery.map((i, k) =>{
            if(i !== null)return `${encodeURIComponent(i[0])}=${encodeURIComponent(i[1])}`
            return 'null=null'
        }).join('&')

        const response = await btApi.get(
            `/api/v1/customers?${filters}`
        );
        return response.data;
    } catch (err) {
        console.log(err);
        let errMessage = "Server Error"
        let status = 500
        try {
            if (err.response.status < 500 && err.response.status > 399) {
                errMessage = err.response.data.message
                status = err.response.status
            }
        } catch {}
        return thunkAPI.rejectWithValue({data: {message: errMessage}, status: status});
    }
})


export const invoicesSlice = createSlice({
    name: 'invoices',
    initialState: {
        isLoadingInvoices : false,
        invoices : [],
        invoiceserror: null,
        invoiceshttpcode: null,
        invoiceCustomers : [],
        invoiceCustomerserror : null,
        invoiceCustomershttpcode : null,
        isLoadingInvoiceCustomers : false,
        loadCustomers : false
    },
    reducers: {
        clearInvoicesError: state => {
            state.invoiceserror = null
            state.invoiceshttpcode = null
        },
        clearInvoiceCustomers: state => {
            state.invoiceCustomers = []
            state.invoiceCustomerserror = null
            state.invoiceCustomershttpcode = null
        },
        matchCustomerInvoice: (state, action) => {
            state.loadCustomers = false
            const customerDict = action.payload.reduce((acc, obj) => {
                acc[obj.key] = obj;
                return acc;
            }, {});

            const updatedInvoices = state.invoices.map(obj => {
                return {
                    ...obj,
                    customer: customerDict[obj.customer_key]
                };
            });

            state.invoices = updatedInvoices
        }
    },

    extraReducers: (builder) => {
        builder
        .addCase(getInvoicesContent.pending, (state, action) => {
            state.invoices = []
            state.invoiceserror = null
            state.invoiceshttpcode = null
            state.isLoadingInvoices = true
            state.loadCustomers = false
        })
        .addCase(getInvoicesContent.fulfilled, (state, action) => {
            state.invoices = action.payload.values
            state.invoiceserror = null
            state.invoiceshttpcode = null
            state.isLoadingInvoices = false
            state.loadCustomers = true
        })
        .addCase(getInvoicesContent.rejected, (state, action) => {
            state.invoices = []
            state.invoiceserror = action.payload.data.message
            state.invoiceshttpcode = action.payload.status
            state.isLoadingInvoices = false
            state.loadCustomers = false
        })
        .addCase(getInvoiceCustomersContent.pending, (state, action) => {
            state.invoiceCustomers = []
            state.invoiceCustomerserror = null
            state.invoiceCustomershttpcode = null
            state.isLoadingInvoiceCustomers = true
        })
        .addCase(getInvoiceCustomersContent.fulfilled, (state, action) => {
            console.log(action.payload.values)
            state.invoiceCustomers = action.payload.values
            state.invoiceCustomerserror = null
            state.invoiceCustomershttpcode = null
            state.isLoadingInvoiceCustomers = false
        })
        .addCase(getInvoiceCustomersContent.rejected, (state, action) => {
            state.invoiceCustomers = []
            state.invoiceCustomerserror = action.payload.data.message
            state.invoiceCustomershttpcode = action.payload.status
            state.isLoadingInvoiceCustomers = false
        })
        .addCase(updateInvoiceObject.fulfilled, (state, action) => {
            const newInvoice = action.payload

            // Loop and update the one object
            const updatedData = state.invoices.map(item => {
              if (item.key === newInvoice.key) {
                return newInvoice;
              }
              return item;
            });
            state.invoices = updatedData
        })
        .addCase(updateInvoiceObject.rejected, (state, action) => {
            state.invoiceserror = action.payload.data.message
            state.invoiceshttpcode = action.payload.status
        })
    }
})

export const { clearInvoiceCustomers, clearInvoicesError, matchCustomerInvoice } = invoicesSlice.actions

export default invoicesSlice.reducer
